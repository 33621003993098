import { centsToPrice } from 'litto-lib/helpers';

import { IAccommodationRead } from 'services/shop/accommodation/interfaces';

export function mapAccommodationToGAItem(
	accommodation: IAccommodationRead,
	position: number
) {
	return {
		item_name: `${accommodation?.translation?.name}`,
		item_id: `${accommodation?.code}`,
		price: `${
			centsToPrice(accommodation.averageNightlyPrice) || 'Dates unselected'
		}`,
        index: position
	};
}
