import React from 'react';
import ReactGA4 from 'react-ga4';

import { useGetGeoFromIP } from 'analytics/services/useGeo';

const useEvent = () => {
	const [firedEvents, setFiredEvents] = React.useState<string[]>([]);
	const { data: country } = useGetGeoFromIP();

	const eventFired = (name: string) => {
		return firedEvents.includes(name);
	};

	const event = React.useCallback(async (name: string, params: any) => {
		if (!eventFired(name)) {
			ReactGA4.event(name, { ...params, country });
			setFiredEvents([...firedEvents, name]);
			return;
		}
		return;
	},[country])
	return { event };
};

export default useEvent;
