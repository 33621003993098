import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { IPromoBannerData } from './interface';

export const getPromoBannerData = async (): Promise<IPromoBannerData> => {
	return await axios
		.get(`${process.env.WORDPRESS_OPTIONS}/promo-banner`, {})
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error('Error loading static page: ', error);
			return Promise.reject(error);
		});
};

const defaultOptions: IUseGetPromoBannerDataQueryOptions = {
	refetchOnWindowFocus: false
};

type IUseGetPromoBannerDataQueryOptions = UseQueryOptions<
	IPromoBannerData,
	unknown,
	IPromoBannerData
>;

export function useGetPromoBannerData(
	options?: IUseGetPromoBannerDataQueryOptions
) {
	return useQuery(['promoBannerData'], getPromoBannerData, {
		...(defaultOptions as any),
		...options
	});
}
