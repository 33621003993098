import { dehydrate, QueryClient } from '@tanstack/react-query';
import { getImageUrl, isEmpty, range } from 'litto-lib/utils';
import { GetStaticProps } from 'next';
import React from 'react';

import useEvent from 'analytics/hooks/useEvent';
import { useGetGeoFromIP } from 'analytics/services/useGeo';
import { AccommodationCard } from 'components/accommodation';
import {
	Header,
	LocationSlider,
	MasterSearch,
	SkeletonCard,
	TipsSlider
} from 'components/shared';
import { BasicLayout } from 'Layout';
import {
	getHostname,
	NextSeo,
	OrganizationJsonLd,
	seoConstants,
	WebsiteJsonld
} from 'seo';
import {
	mapAccommodationsToGAItems,
	mapAccommodationToGAItem
} from 'seo/utils/gtm/ga4';
import {
	getAccommodations,
	useGetAccommodationsQuery
} from 'services/shop/accommodation';
import { IAccommodationRead } from 'services/shop/accommodation/interfaces';
import {
	getLocations,
	locationKeys,
	useGetLocationsQuery
} from 'services/shop/location';
import {
	getPromoBannerData,
	useGetPromoBannerData
} from 'services/wordpress/data';
import { getHomepage, useGetHomepageQuery } from 'services/wordpress/homepage';

const defaultLocationsParams = {
	homepageHighlighted: true
};

const defaultAccommodationsParams = {
	page: 1,
	itemsPerPage: 12,
	type: 'homepage_listing'
};

const Home = () => {
	const { data: cities } = useGetLocationsQuery({
		params: {
			...defaultLocationsParams
		}
	});

	const { data: accommodations, isLoading } = useGetAccommodationsQuery(
		{
			params: {
				...defaultAccommodationsParams
			}
		},
		{ refetchOnWindowFocus: false }
	);

	const { data: homepage } = useGetHomepageQuery();

	const { data: promoBanner } = useGetPromoBannerData();

	const { event } = useEvent();
	const { data: country } = useGetGeoFromIP();

	React.useEffect(() => {
		if (
			accommodations &&
			country &&
			!isEmpty(accommodations?.['hydra:member']) &&
			!isLoading
		) {
			const items = mapAccommodationsToGAItems(accommodations['hydra:member']);
			event('view_item_list', {
				item_list_id: 'homepage_most_popular_accommodations',
				item_list_name: 'Homepage - Most Popular - Accommodations',
				items,
				country
			});
		}
	}, [accommodations?.['hydra:member'], country]);

	const onHomePageAccommodationClick = (
		item: IAccommodationRead,
		position: number
	) => {
		const accommodationData = mapAccommodationToGAItem(item, position);
		event('select_item', {
			item_list_id: 'homepage_most_popular_accommodations',
			item_list_name: 'Homepage - Most Popular - Accommodations',
			items: [
				{
					...accommodationData,
					item_list_id: 'homepage_most_popular_accommodations',
					item_list_name: 'Homepage - Most Popular - Accommodations'
				}
			]
		});
	};

	if (!homepage) return null;

	return (
		<BasicLayout noHeader>
			<NextSeo
				openGraph={{
					title: seoConstants.mainTitle,
					description: seoConstants.mainDescription,
					type: 'website',
					url: getHostname()
				}}
			/>
			<WebsiteJsonld />
			<OrganizationJsonLd
				url={seoConstants.domain}
				name={seoConstants.name}
				legalName={seoConstants.legalName}
				logo="https://litto.co/assets/ui-icons/litto-logo.svg"
				organizationType="LocalBusiness"
				contactPoints={[
					{
						telephone: '091 177 0310',
						contactType: 'Info'
					}
				]}
				geoCoordinates={{
					latitude: '43.513043271112785',
					longitude: '16.485225927132177'
				}}
			/>
			<Header
				className="absolute z-20 w-screen"
				hasSearch={false}
				theme="transparent"
				slotRight={
					<div className="flex flex-col space-y-1 text-right font-bold text-white sm:flex-row sm:space-x-2 sm:space-y-0">
						<a className="hover:opacity-80" href="mailto:support@litto.co">
							support@litto.co
						</a>
						<span className="relative top-1 hidden text-xs text-gray-300 sm:inline">
							•
						</span>
						<a className="hover:opacity-80" href="tel:385911770310">
							+385 91 1770310
						</a>
					</div>
				}
			/>
			<div className="aspect-w-3 aspect-h-5 relative z-10 flex items-center justify-center  sm:aspect-w-1 sm:aspect-h-1 md:aspect-w-12 md:aspect-h-11 lg:aspect-w-2 lg:aspect-h-1 xl:aspect-w-3">
				<div className="flex h-full flex-col items-end justify-end xl:items-center xl:justify-center ">
					<picture className="absolute left-0 top-0 h-full w-full bg-gray-100">
						<source
							media="(max-width: 420px)"
							srcSet={getImageUrl(
								homepage.acf.hero_images.mobile_image.url
							).replace(String(process.env.WORDPRESS_URL_BASE), '/')}
						/>
						<img
							className="absolute left-0 top-0 h-full w-full object-cover object-center brightness-75"
							src={getImageUrl(
								homepage.acf.hero_images.desktop_image.url
							).replace(String(process.env.WORDPRESS_URL_BASE), '/')}
							alt="Litto homepage hero image"
						/>
					</picture>

					<div className="container relative z-20 flex h-full w-full flex-col items-center justify-between md:h-auto lg:flex-row xl:flex-col">
						<h1 className="my-auto max-w-screen-lg whitespace-pre-wrap pt-14 text-center text-2xl text-white sm:text-3xl md:mb-10 md:pt-0 md:text-4xl lg:mb-20 lg:pr-8 lg:text-left xl:mb-10 xl:pr-0 xl:text-center">
							{homepage.acf.hero_text}
						</h1>
						<MasterSearch className="-mx-5 flex-none translate-y-10 transform shadow-lg sm:mx-auto sm:w-106 sm:translate-y-10 md:ml-auto md:mr-0 xl:mx-auto xl:w-full xl:transform-none" />
					</div>
				</div>
			</div>
			<div className="my-20">
				<h2 className="container mb-5 text-2xl font-bold">
					Explore amazing locations in Croatia
				</h2>
				<LocationSlider
					{...(cities
						? {
								cities: cities?.['hydra:member']
						  }
						: null)}
				/>
			</div>

			<div className="my-30">
				{!isLoading && !isEmpty(accommodations?.['hydra:member']) && (
					<h2 className="container mb-5 text-2xl font-bold">
						Most popular accommodations in Croatia
					</h2>
				)}
				<div data-testid="accommodationsList" className="container ">
					<div className="5 -mx-2 flex flex-wrap">
						{isLoading &&
							accommodations &&
							isEmpty(accommodations?.['hydra:member']) &&
							range(0, 12).map(i => (
								<SkeletonCard
									className={`mb-8 w-full px-2.5 sm:mb-9.5 sm:w-1/2 lg:w-1/3 xl:w-1/4`}
									key={i}
								/>
							))}
						{!isLoading &&
							!isEmpty(accommodations?.['hydra:member']) &&
							accommodations?.['hydra:member'].map((product, i) => {
								return (
									<AccommodationCard
										key={product.code}
										onClick={() => {
											onHomePageAccommodationClick(product, i);
										}}
										className={`mb-8 w-full px-2.5 sm:mb-9.5 sm:w-1/2 lg:w-1/3 xl:w-1/4`}
										product={product}
									/>
								);
							})}
					</div>
				</div>
			</div>
			{promoBanner && <TipsSlider tipsArray={promoBanner?.acf?.promo_banner} />}
			{/* {homepage?.acf?.explore_slider && (
				<div data-testid="homepageBanner" className="my-10">
					<h2 className="container mb-5 text-2xl font-bold">
						Didn’t find anything breathtaking? Explore similar cities!{' '}
					</h2>
					<BannerSlider bannerContent={homepage?.acf?.explore_slider} />
				</div>
			)} */}
			{/* {homepage?.acf?.banner && (
				<div className="mt-20 sm:container sm:my-20">
					<OverlayedCard
						aspectRatio="3-1"
						imgSrc={getImageUrl(homepage?.acf?.banner?.image?.url)?.replace(
							process.env.WORDPRESS_URL_BASE,
							'/'
						)}
						title={
							<h2 className="text-2xl text-white sm:max-w-lg sm:self-start">
								{homepage?.acf?.banner?.text}
							</h2>
						}
						slotBottom={
							<Button
								href={`${
									homepage?.acf?.banner?.link
										? homepage?.acf?.banner?.link
										: '#'
								}`}
								className="self-start mt-6 no-underline text-primary-700"
								text="Explore"
								color="brand"
							/>
						}
					/>
				</div>
			)} */}
		</BasicLayout>
	);
};

export const getStaticProps: GetStaticProps = async () => {
	const queryClient = new QueryClient();

	const [homepage, dataPromoBanner, locations, homepageAccommodations] =
		await Promise.all([
			getHomepage(),
			getPromoBannerData(),
			getLocations({ params: defaultLocationsParams }),
			getAccommodations({ params: { ...defaultAccommodationsParams } })
		]);

	await Promise.all([
		queryClient.prefetchQuery(['homepage'], () => {
			return homepage;
		}),
		queryClient.prefetchQuery(['promoBannerData'], () => {
			return dataPromoBanner;
		}),

		queryClient.prefetchQuery(locationKeys.list(defaultLocationsParams), () => {
			return locations;
		}),
		queryClient.prefetchQuery(
			[
				'accommodations',
				...Object.entries(defaultAccommodationsParams || {}).sort()
			],
			() => {
				return homepageAccommodations;
			}
		)
	]);

	return {
		props: {
			dehydratedState: dehydrate(queryClient)
		},
		revalidate: 60
	};
};

export default Home;
