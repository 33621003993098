import { mapAccommodationToGAItem } from './mapAccommodationToGAItem';
import { IAccommodationRead } from 'services/shop/accommodation/interfaces';

export function mapAccommodationsToGAItems(
	accommodations: IAccommodationRead[],
	startingPosition?: number
) {
	return accommodations.map((accommodation: IAccommodationRead, i: number) => {
		const itemParams = mapAccommodationToGAItem(
			accommodation,
			(startingPosition || 0) + i + 1
		);
		return {
			...itemParams
		};
	});
}
