import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { QueryOptionsOmit } from 'litto-lib/types';


export interface IGeoResponse {
	ip: string;
	continent_code: string;
	continent_name: string;
	country_code2: string;
	country_code3: string;
	country_name: string;
	country_capital: string;
	state_prov: string;
	district: string;
	city: string;
	zipcode: string;
	latitude: string;
	longitude: string;
	is_eu: boolean;
	calling_code: string;
	country_tld: string;
	languages: string;
	country_flag: string;
	geoname_id: string;
	isp: string;
	connection_type: string;
	organization: string;
	currency: ICurrency;
	time_zone: ITimeZone;
}

export interface ICurrency {
	code: string;
	name: string;
	symbol: string;
}

export interface ITimeZone {
	name: string;
	offset: number;
	current_time: string;
	current_time_unix: number;
	is_dst: boolean;
	dst_savings: number;
}

export const getGeolocationBasedOnIP = async (): Promise<string> => {
	return await axios
		.get<IGeoResponse>(
			`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.NEXT_PUBLIC_IPGEOLOCATION_API_KEY}`
		)
		.then(response => {
			return Promise.resolve(response.data.country_code2);
		})
		.catch(error => {
			console.error(`Error getting geo data`, error);
			return Promise.reject(error);
		});
};

export function useGetGeoFromIP(
	options?: QueryOptionsOmit<UseQueryOptions<IGeoResponse, any, IGeoResponse>>
) {
	return useQuery(
		['analytics', 'geo'],
		() => {
			return getGeolocationBasedOnIP();
		},
		options as any
	);
}
