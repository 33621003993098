import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface IHomepageAcf {
	acf: {
		hero_text: string;
		hero_images: {
			desktop_image: { url: string };
			mobile_image: { url: string };
		};
	};
}

export const getHomepage = async () => {
	return await axios
		.get<IHomepageAcf[]>(
			`${process.env.WORDPRESS_URL}/pages?slug=homepage&_fields=acf.hero_text,acf.hero_images.desktop_image.url,acf.hero_images.mobile_image.url`,
			{}
		)
		.then(response => {
			return Promise.resolve(response.data[0]);
		})
		.catch(error => {
			console.error('Error loading static page: ', error);
			return Promise.reject(error);
		});
};

export function useGetHomepageQuery() {
	return useQuery(['homepage'], () => getHomepage());
}
